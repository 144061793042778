import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { LOAD_ALL_POSTS } from '@/constants/variables';

export const useBlogLogic = () => {
  const router = useRouter();
  const handleCategoryClick = useCallback((category: string) => {
    if (category === LOAD_ALL_POSTS) {
      router.replace({
        pathname: router.pathname,
      });
    } else {
      router.replace({
        pathname: router.pathname,
        query: {
          category,
        },
      });
    }
  }, []);

  return { handleCategoryClick };
};
