import { useRouter } from 'next/router';
import React, { useCallback } from 'react';

import { Description, Heading, Tag, Typography } from '@/components';
import {
  LOAD_ALL_POSTS,
  REDIRECT_TO_BLOG,
  REDIRECT_TO_DEVELOPER_RESOURCES,
} from '@/constants/variables';
import { useBlogLogic } from '@/hooks/useBlogLogic';
import { Container } from '@/layouts/Container';
import { ConvertStringToDateTime } from '@/utils/DateFormat';
import getImage from '@/utils/getImage';

import type { BlogCardProps, BlogPostProps, CategoryProps } from './types';

const Categories = ({ categories, onClick }: CategoryProps) => {
  const { query } = useRouter();

  return (
    <div className="my-6 flex gap-10 sm:my-default">
      <div
        onClick={() => onClick(LOAD_ALL_POSTS ?? '')}
        className="cursor-pointer"
      >
        <Heading
          level={4}
          color={!query.category ? 'dark' : 'lightDark'}
          weight={!query.category ? 'bold' : 'normal'}
        >
          {LOAD_ALL_POSTS}
        </Heading>
      </div>
      {categories.map((category) => (
        <div
          key={category.id}
          onClick={() => onClick(category.attributes.name ?? '')}
          className="cursor-pointer"
        >
          <Heading
            level={4}
            color={
              query.category === category.attributes.name ? 'dark' : 'lightDark'
            }
            weight={
              query.category === category.attributes.name ? 'bold' : 'normal'
            }
          >
            {category.attributes.name}
          </Heading>
        </div>
      ))}
    </div>
  );
};

const BlogCard = ({
  title,
  img,
  categories,
  date,
  duration,
  content,
  author,
  url,
}: BlogCardProps) => {
  const router = useRouter();

  const handlePush = useCallback(() => {
    if (router.pathname === '/blogs') {
      router.push(REDIRECT_TO_BLOG(url));
    }
    if (router.pathname === '/developer-resources') {
      router.push(REDIRECT_TO_DEVELOPER_RESOURCES(url));
    }
  }, [router.pathname]);

  return (
    <div className="flex w-full cursor-pointer flex-col" onClick={handlePush}>
      <img
        src={img}
        alt={title}
        className="aspect-[77/48] w-full rounded-t-lg object-cover object-center"
      />
      <div className="mt-3 flex w-full flex-wrap gap-2 sm:mt-8">
        {categories &&
          categories.data.map((category, index) => (
            <Tag
              key={category.id}
              index={index}
              label={category.attributes.name ?? ''}
            />
          ))}
      </div>
      <Heading level={4} className="mt-3 sm:mt-4" weight="semibold">
        {title}
      </Heading>
      <div className="mt-2 flex items-center gap-3 text-sm font-semibold text-primary-600 sm:mt-3">
        <Typography size="small" variant="noStyle">
          {date}
        </Typography>
        <div>
          <ul className="list-inside list-disc">
            <li>{duration} mins read</li>
          </ul>
        </div>
      </div>
      <Typography size="large" variant="lightDark" className="mt-2 sm:mt-3">
        <Description wordLimit={40}>{content}</Description>
      </Typography>
      {author && (
        <div className="mt-3 flex h-full items-end sm:mt-8">
          <div className=" flex  items-center gap-4 ">
            {author.data.attributes.profilePhoto && (
              <div className="h-10 w-10 rounded-full">
                <img
                  src={getImage(
                    author.data.attributes.profilePhoto?.data?.attributes.url
                  )}
                  alt={
                    author.data.attributes.profilePhoto?.data?.attributes
                      .alternativeText
                  }
                  className="rounded-full"
                />
              </div>
            )}
            <div className="flex flex-col">
              <Typography className="font-bold">
                {author.data.attributes.displayName}
              </Typography>
              <Typography size="small">
                {author.data.attributes.designation}
              </Typography>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const BlogPost = ({
  categories,
  posts,
  withBorder = true,
}: BlogPostProps) => {
  const { handleCategoryClick } = useBlogLogic();
  return (
    <Container>
      <div
        className={withBorder ? 'border-t border-solid border-primary-200' : ''}
      >
        {categories && categories.length > 0 && (
          <Categories categories={categories} onClick={handleCategoryClick} />
        )}
        <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-8 sm:gap-y-16">
          {posts.length > 0
            ? posts.map((post) => (
                <BlogCard
                  key={post.id}
                  url={post.attributes.url}
                  id={post.id}
                  title={post.attributes.title}
                  img={getImage(
                    post.attributes.featured_image?.data?.attributes.url
                  )}
                  date={ConvertStringToDateTime(
                    post.attributes.publishDate,
                    'only-date'
                  )}
                  duration={post.attributes.reading_time}
                  categories={post.attributes.categories ?? null}
                  content={post.attributes.description}
                  author={post.attributes.author}
                />
              ))
            : 'NO POSTS'}
        </div>
      </div>
    </Container>
  );
};
