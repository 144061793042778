import React from 'react';

import { Heading, Typography } from '@/components';
import { Container } from '@/layouts/Container';
import type { FormProps } from '@/page-components/Home/ContactForm/types';
import getImage from '@/utils/getImage';

import { ClientForm } from '../../Home/ContactForm/ClientForm';
import type { ContactUsProps } from '../types';

export const ContactUsMobile = ({
  email,
  phone,
  address,
  socials,
  RenderAlert,
  onSubmit,
}: ContactUsProps & FormProps) => {
  return (
    <>
      <div className="flex flex-col gap-10">
        <Container>
          {RenderAlert()}
          <ClientForm onSubmit={onSubmit} />
        </Container>
        <div className="bg-primary-900 py-14 text-white">
          <Container>
            <Heading level={2} color="white">
              Contact Us
            </Heading>
            <Typography
              variant="noStyle"
              size="xl"
              className="mt-4 font-medium underline"
            >
              {email} <br /> {phone}
            </Typography>
            <Typography
              variant="noStyle"
              size="xl"
              className="mt-4 w-[432px] font-medium"
            >
              {address}
            </Typography>
            <div className="mt-4 flex gap-5">
              {socials &&
                socials.length &&
                socials.map((social) => (
                  <a href={social.href} key={social.id}>
                    <img
                      src={getImage(social.icon?.data?.attributes.url)}
                      alt={social.icon.data.attributes.alternativeText}
                      className="h-8 w-8"
                    />
                  </a>
                ))}
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};
