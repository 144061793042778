import clsx from 'clsx';
import { useRouter } from 'next/router';
import React from 'react';

import { Button, Heading, ScreenResizer, Typography } from '@/components';
import {
  REDIRECT_TO_SUCCESS_STORIES,
  STORIES_BG_COLORS,
} from '@/constants/variables';
import { Container } from '@/layouts/Container';
import type { BlogData } from '@/types/blogResponse';
import getImage from '@/utils/getImage';

export const Stories = ({ stories }: { stories: BlogData[] }) => {
  const dynamicBackgrounds = (index: number) => {
    return STORIES_BG_COLORS[index % STORIES_BG_COLORS.length];
  };

  const router = useRouter();

  return (
    <div>
      {stories.map((story, index) => (
        <div
          key={story.id}
          style={{ backgroundColor: dynamicBackgrounds(index) }}
        >
          <Container>
            <div className="flex flex-col items-center justify-between gap-4 py-14 sm:flex-row sm:gap-0">
              <ScreenResizer displayOnly="desktop">
                {index % 2 !== 0 && (
                  <div>
                    <img
                      src={getImage(
                        story.attributes.featured_image?.data?.attributes.url
                      )}
                      alt={
                        story.attributes.featured_image?.data?.attributes
                          .alternativeText
                      }
                      className="max-h-[508px] max-w-[480px]"
                    />
                  </div>
                )}
              </ScreenResizer>
              <div
                className={clsx(
                  'flex w-full',
                  index % 2 === 0 && 'justify-start',
                  index % 2 !== 0 && 'justify-end'
                )}
              >
                <div className="sm:w-[600px]">
                  <Heading level={3} color="white">
                    {story.attributes.title}
                  </Heading>
                  <Typography size="xl" className="mt-6 font-medium text-white">
                    {story.attributes.description}
                  </Typography>
                  <Button
                    label="View Success Story"
                    variant="white"
                    className="mt-6 sm:mt-10"
                    onClick={() =>
                      router.push(
                        REDIRECT_TO_SUCCESS_STORIES(story.attributes.url)
                      )
                    }
                  />
                </div>
              </div>

              <ScreenResizer displayOnly="desktop">
                {index % 2 === 0 && (
                  <div>
                    <img
                      src={getImage(
                        story.attributes.featured_image?.data?.attributes.url
                      )}
                      alt="success"
                      className="max-h-[508px] max-w-[480px]"
                    />
                  </div>
                )}
              </ScreenResizer>
              <ScreenResizer displayOnly="mobile">
                <div>
                  <img
                    src={getImage(
                      story.attributes.featured_image?.data?.attributes.url
                    )}
                    alt="success"
                    className="max-h-[508px] max-w-full"
                  />
                </div>
              </ScreenResizer>
            </div>
          </Container>
        </div>
      ))}
    </div>
  );
};
