import React from 'react';

import { ScreenResizer } from '@/components';

import { NewsletterMobile } from './mobile';
import { NewsletterWeb } from './web';

export const Newsletter = () => {
  return (
    <>
      <ScreenResizer displayOnly="desktop">
        <NewsletterWeb />
      </ScreenResizer>
      <ScreenResizer displayOnly="mobile">
        <NewsletterMobile />
      </ScreenResizer>
    </>
  );
};
