import clsx from 'clsx';
import React from 'react';

import { Container } from '@/layouts/Container';

import type { IndustriesServedProps, IndustryCardProps } from '../types';

const IndustryCard = ({ name, icon, className }: IndustryCardProps) => (
  <div
    className={clsx(
      className,
      'flex flex-col justify-center gap-1 bg-footer p-1 hover:drop-shadow-md'
    )}
  >
    <div className="flex w-full justify-center">{icon}</div>
    <p className="text-center text-[6px] font-medium text-primary-700">
      {name}
    </p>
  </div>
);

export const IndustriesServedMobile = ({
  industries,
}: IndustriesServedProps) => {
  return (
    <Container>
      <div
        className="grid justify-center gap-1"
        style={{ gridTemplateColumns: 'repeat(6, 53px)' }}
      >
        {industries.map((industry, index, arr) => {
          if (index === 0)
            return (
              <div className="flex flex-col justify-center">
                <IndustryCard
                  className="h-[41px]"
                  name={industry.name}
                  icon={industry.icon}
                />
              </div>
            );
          if (index === 1)
            return (
              <div className="flex flex-col justify-center gap-2">
                <IndustryCard
                  name={industry.name}
                  icon={industry.icon}
                  className="h-[41px]"
                />
                <IndustryCard
                  className="h-[41px]"
                  name={arr[index + 1]?.name ?? ''}
                  icon={arr[index + 1]?.icon ?? <></>}
                />
              </div>
            );
          if (index === 3)
            return (
              <div className="col-span-2 grid grid-cols-2 gap-2">
                <div className="col-span-2">
                  <IndustryCard
                    className="h-[41px]"
                    name={industry.name}
                    icon={industry.icon}
                  />
                </div>
                <IndustryCard
                  className="h-[41px]"
                  name={arr[index + 1]?.name ?? ''}
                  icon={arr[index + 1]?.icon ?? <></>}
                />
                <IndustryCard
                  className="h-[41px]"
                  name={arr[index + 2]?.name ?? ''}
                  icon={arr[index + 2]?.icon ?? <></>}
                />

                <div className="col-span-2">
                  <IndustryCard
                    className="h-[41px]"
                    name={arr[index + 3]?.name ?? ''}
                    icon={arr[index + 3]?.icon ?? <></>}
                  />
                </div>
              </div>
            );
          if (index === 7)
            return (
              <div className="flex flex-col justify-center gap-2">
                <IndustryCard
                  className="h-[41px]"
                  name={industry.name}
                  icon={industry.icon}
                />
                <IndustryCard
                  className="h-[41px]"
                  name={arr[index + 1]?.name ?? ''}
                  icon={arr[index + 1]?.icon ?? <></>}
                />
              </div>
            );
          if (index === 9)
            return (
              <div className="flex flex-col justify-center">
                <IndustryCard
                  className="h-[41px]"
                  name={industry.name}
                  icon={industry.icon}
                />
              </div>
            );
          return <></>;
        })}
      </div>
    </Container>
  );
};
