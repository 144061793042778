import { NextSeo } from 'next-seo';
import React from 'react';

import useMetaSocial from '@/hooks/useMetaSocial';
import type { BlogData } from '@/types/blogResponse';
import type { ServiceType } from '@/types/service';
import type { SolutionType } from '@/types/solution';
import type { SuccessStoriesType } from '@/types/successStories';
import getImage from '@/utils/getImage';

export const SEO = ({
  postData,
}: {
  postData: BlogData | SuccessStoriesType | SolutionType | ServiceType;
}) => {
  const { metaOG, metaTwitter } = useMetaSocial(
    postData?.attributes?.seo?.metaSocial ?? null
  );

  return (
    <NextSeo
      title={postData?.attributes?.seo?.metaTitle}
      description={postData?.attributes?.seo?.metaDescription}
      canonical={postData?.attributes?.seo?.canonicalURL ?? ''}
      openGraph={{
        title: metaOG?.title,
        description: metaOG?.description,
        images: [
          {
            url: getImage(metaOG?.image?.data?.attributes.url) ?? '',
            height: 500,
            width: 500,
            alt: metaOG?.image?.data?.attributes.alternativeText,
            type: metaOG?.image?.data?.attributes.mime,
          },
        ],
      }}
      additionalMetaTags={[
        {
          property: 'twitter:title',
          content: metaTwitter?.title ?? '',
        },
        {
          property: 'twitter:description',
          content: metaTwitter?.description ?? '',
        },
        {
          property: 'twitter:image',
          content: getImage(metaTwitter?.image?.data?.attributes.url) ?? '',
        },
        {
          property: 'keywords',
          content: postData?.attributes?.seo?.keywords ?? '',
        },
      ]}
    />
  );
};
