import React from 'react';

import { Description, Heading, Typography } from '@/components';
import { Container } from '@/layouts/Container';
import type { TechnologyWorkProcessType } from '@/types/service';
import getImage from '@/utils/getImage';

import type { ProcessCardProps } from './types';

const ProcessCard = ({ index, title, description, icon }: ProcessCardProps) => (
  <div
    className="flex min-h-[324px] flex-col items-center gap-3 bg-customPrimary p-5 sm:p-6"
    data-aos={index ?? 0 % 2 === 0 ? 'fade-left' : 'fade-right'}
  >
    <div className="flex justify-center">
      <div className="rounded-full bg-siteBg p-5">
        <img
          src={icon}
          alt={title}
          className="h-[60px] w-[60px] object-contain"
        />
      </div>
    </div>
    <Heading level={4} color="white" className="mt-2 text-center">
      {title}
    </Heading>
    <Typography
      size="large"
      variant="noStyle"
      className="text-center text-textColorLight"
    >
      <Description wordLimit={52}>{description}</Description>
    </Typography>
  </div>
);

export const WorkProcess = ({
  data,
}: {
  data: TechnologyWorkProcessType[];
}) => {
  return (
    <div className="bg-primary-900 py-6 sm:py-default">
      <Container>
        <div data-aos="fade-down">
          <Heading level={2} color="white" className="text-center">
            Our Work Process
          </Heading>
        </div>
        <div className="mt-6 grid grid-cols-1 gap-4 sm:mt-default sm:grid-cols-2 sm:gap-8">
          {data &&
            data.map((item, index) => (
              <ProcessCard
                key={index}
                index={index}
                title={item.title}
                icon={getImage(item.icon.data.attributes.url)}
                description={item.description}
              />
            ))}
        </div>
      </Container>
    </div>
  );
};
