import type { MetaSocial } from '@/types/blogResponse';

type MetaSocialProps = MetaSocial[] | null;

const useMetaSocial = (metaSocial: MetaSocialProps) => {
  const metaOG = metaSocial?.find((meta) =>
    meta.socialNetwork.match(/facebook/gi)
  );

  const metaTwitter = metaSocial?.find((meta) =>
    meta.socialNetwork.match(/twitter/gi)
  );

  return { metaOG, metaTwitter };
};

export default useMetaSocial;
