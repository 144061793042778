import React from 'react';

import { Heading, Typography } from '@/components';
import { Container } from '@/layouts/Container';
import type { TeamMemberType } from '@/types/team';
import getImage from '@/utils/getImage';

export const OurTeam = ({ members }: { members: TeamMemberType[] }) => {
  return (
    <Container>
      <div data-aos="fade-down">
        <Heading level={2} className="text-center">
          Meet Our Team
        </Heading>
      </div>
      <div
        className="mt-6 grid grid-cols-2 gap-4 sm:mt-default sm:grid-cols-4 sm:gap-8"
        data-aos="fade-down"
      >
        {members &&
          members.map((member, index) => (
            <div key={index}>
              <img
                src={getImage(member.attributes.photo.data?.attributes.url)}
                alt={member.attributes.name}
                className="aspect-[159/149] w-full object-cover object-center sm:aspect-[141/118]"
              />
              <Heading level={4} className="mt-3 text-center sm:mt-4">
                {member.attributes.name}
              </Heading>
              <Typography
                size="large"
                className="mt-0 text-center font-medium sm:mt-3"
                variant="lightDark"
              >
                {member.attributes.designation}
              </Typography>
            </div>
          ))}
      </div>
    </Container>
  );
};
