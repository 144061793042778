import { useRouter } from 'next/router';
import React from 'react';

import { Heading, Tag, Typography } from '@/components';
import { REDIRECT_TO_BLOG } from '@/constants/variables';
import { Container } from '@/layouts/Container';
import type { BlogData } from '@/types/blogResponse';
import { ConvertStringToDateTime } from '@/utils/DateFormat';
import getImage from '@/utils/getImage';

export const FeaturedPost = ({ post }: { post: BlogData }) => {
  const router = useRouter();
  return (
    <Container>
      <div className="flex flex-col items-center gap-4 sm:flex-row sm:gap-10">
        <img
          src={getImage(post.attributes.featured_image?.data?.attributes.url)}
          alt="featured blog"
          className="h-fit w-full cursor-pointer rounded-lg object-cover object-center sm:h-[380px] sm:w-[600px]"
          onClick={() => router.push(REDIRECT_TO_BLOG(post.attributes.url))}
          data-aos="fade-right"
        />

        <div data-aos="fade-left">
          <div className="flex flex-wrap gap-2">
            {post.attributes.categories &&
              post.attributes.categories.data.map((category, index) => (
                <Tag
                  key={category.id}
                  index={index}
                  label={category.attributes.name ?? ''}
                />
              ))}
          </div>
          <div
            className="cursor-pointer"
            onClick={() => router.push(REDIRECT_TO_BLOG(post.attributes.url))}
          >
            <Heading
              level={0}
              className="mt-5 text-2xl !leading-[44px] sm:text-[36px]"
              weight="semibold"
            >
              {post.attributes.title}
            </Heading>
          </div>
          <div className="mt-2 flex items-center gap-3 text-sm font-semibold text-primary-600">
            <Typography size="small" variant="noStyle">
              {ConvertStringToDateTime(
                post.attributes.publishDate,
                'only-date'
              )}
            </Typography>
            <div>
              <ul className="list-inside list-disc">
                <li>{post.attributes.reading_time} mins read</li>
              </ul>
            </div>
          </div>
          <Typography size="xl" variant="lightDark" className="mt-2">
            {post.attributes.description}
          </Typography>

          <div className="mt-6 flex items-center gap-4">
            {post.attributes.author?.data.attributes.profilePhoto && (
              <div className="h-10 w-10 rounded-full">
                <img
                  src={getImage(
                    post.attributes.author?.data?.attributes.profilePhoto?.data
                      ?.attributes.url
                  )}
                  alt={
                    post.attributes.author?.data.attributes.profilePhoto.data
                      .attributes.alternativeText
                  }
                  className="rounded-full"
                />
              </div>
            )}

            <div className="flex flex-col">
              <Typography className="font-bold">
                {post.attributes.author?.data.attributes.displayName}
              </Typography>
              <Typography
                size="small"
                variant="lightDark"
                className="font-medium"
              >
                {post.attributes.author?.data.attributes.designation}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
