import { useRouter } from 'next/router';
import React from 'react';

import { Button, Heading } from '@/components';
import { TALK_TO_US_URL } from '@/constants/variables';
import { Container } from '@/layouts/Container';
import type { ClientType } from '@/types/clients';
import getImage from '@/utils/getImage';

export const CompaniesServed = ({ clients }: { clients: ClientType[] }) => {
  const router = useRouter();

  return (
    <div className="bg-[url('/assets/common/contact-form-bg.png')] bg-cover bg-no-repeat py-6 sm:py-default">
      <Container>
        <div className="flex flex-col gap-4 sm:flex-row sm:justify-between sm:gap-0">
          <div className="w-full sm:w-[700px]" data-aos="fade-right">
            <Heading level={2}>
              We have successfully completed 350+ projects across 15 industries
            </Heading>
            <p className="mt-6 text-lg text-primary-700 sm:text-xl">
              Hire us for your next project. Request a free quote. We will send
              a precise calculation soon.
            </p>
            <Button
              label="Talk To Us"
              variant="black"
              className="mt-4 sm:mt-10"
              onClick={() => router.push(TALK_TO_US_URL)}
            />
          </div>
          <div
            className="mt-6 w-full sm:mt-0 sm:h-[270px] sm:w-[500px]"
            data-aos="fade-left"
          >
            <div className="flex flex-wrap gap-4">
              {clients &&
                clients.slice(0, 9).map((client) => (
                  <img
                    key={client.id}
                    src={getImage(client.attributes.logo?.data?.attributes.url)}
                    height={50}
                    width={150}
                    alt={client.attributes.alt_logo}
                    style={{
                      objectFit: 'contain',
                    }}
                  />
                ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
