import { Formik } from 'formik';
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Yup from 'yup';

// eslint-disable-next-line import/no-cycle
import {
  Button,
  FileUpload,
  Input,
  MailIcon,
  PhoneIcon,
  Select,
  Textarea,
} from '@/components';
import { PDF_FILE_TYPE, WORD_FILE_TYPES } from '@/constants/fileTypes';
import { REQUIRED_FIELD_MESSAGE } from '@/constants/variables';

import type { CareerFormProps, CareerInitialValues } from './types';

const initialValues: CareerInitialValues = {
  name: '',
  email: '',
  phone: '',
  interestedIn: '',
  expertise: '',
  files: null,
  description: '',
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  email: Yup.string().email().required(REQUIRED_FIELD_MESSAGE),
  phone: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  interestedIn: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  expertise: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  files: Yup.mixed().required(REQUIRED_FIELD_MESSAGE),
  description: Yup.string().min(5).max(500).required(REQUIRED_FIELD_MESSAGE),
  recaptchaValidation: Yup.boolean(),
});

export const CareerForm = ({ onSubmit }: CareerFormProps) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        setFieldValue,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <Input
              name="name"
              label="Name"
              placeholder="Name"
              onChange={handleChange}
              value={values.name}
              transparent
              isDirty={touched.name}
              validationError={
                touched.name && errors.name ? errors.name : undefined
              }
            />

            <Input
              name="phone"
              label="Phone number"
              placeholder="Phone number"
              onChange={handleChange}
              transparent
              value={values.phone}
              isDirty={touched.phone}
              validationError={
                touched.phone && errors.phone ? errors.phone : undefined
              }
              startIcon={<PhoneIcon className="w-2 text-primary-500" />}
            />

            <div className="sm:col-span-2">
              <Input
                name="email"
                label="Email"
                placeholder="xyz@gmail.com"
                onChange={handleChange}
                transparent
                value={values.email}
                isDirty={touched.email}
                validationError={
                  touched.email && errors.email ? errors.email : undefined
                }
                startIcon={<MailIcon className="text-primary-500" />}
              />
            </div>

            <Select
              options={[
                'Full-time position',
                'Part-time position',
                'Contractual position',
                'Internship',
              ]}
              name="interestedIn"
              label="Interested in"
              placeholder="Select Interested In"
              onChange={handleChange}
              transparent
              value={values.interestedIn}
              isDirty={touched.interestedIn}
              validationError={
                touched.interestedIn && errors.interestedIn
                  ? errors.interestedIn
                  : undefined
              }
            />
            <Select
              options={[
                'Backend Development',
                'Frontend Development',
                'Full-stack Development',
                'Flutter',
                'Android (Kotlin/Java)',
                'iOS Development (Swift)',
                'SQA',
                'UI/UX Design',
                'Sales & Marketing',
                'Other',
              ]}
              name="expertise"
              label="Area of Expertise"
              placeholder="Select Area Of Expertise"
              onChange={handleChange}
              transparent
              value={values.expertise}
              isDirty={touched.expertise}
              validationError={
                touched.expertise && errors.expertise
                  ? errors.expertise
                  : undefined
              }
            />
            <div className="sm:col-span-2">
              <Textarea
                name="description"
                label="Description"
                placeholder="Enter a description..."
                transparent
                onChange={handleChange}
                rows={4}
                value={values.description}
                validationError={
                  touched.description && errors.description
                    ? errors.description
                    : undefined
                }
              />
            </div>

            <div className="sm:col-span-2">
              <FileUpload
                name="files"
                supportedFormats={[...WORD_FILE_TYPES, PDF_FILE_TYPE]}
                accept={`application/pdf,${WORD_FILE_TYPES.join()}`}
                files={values.files ?? null}
                setFieldValue={setFieldValue}
              />
              {touched.files && errors.files && (
                <p className="mt-2 text-xs text-red-700">{errors.files}</p>
              )}
            </div>
          </div>
          <Button
            loading={isSubmitting}
            disabled={isSubmitting}
            className="mt-11"
            label="Submit"
            variant="black"
            type="submit"
          />
        </form>
      )}
    </Formik>
  );
};
