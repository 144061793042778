import React from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { HTMLParser, ScreenResizer, SocialShare } from '@/components';
import { Container } from '@/layouts/Container';

type PostContentProps = {
  html: string;
  isSuggested?: boolean;
};

export const PostContent = ({ html }: PostContentProps) => {
  return (
    <Container hiddenOverflow>
      <div className="relative flex w-full flex-none sm:justify-center sm:py-container">
        <ScreenResizer displayOnly="desktop">
          <div className="sticky top-[150px] mr-10">
            <SocialShare />
          </div>
        </ScreenResizer>
        <div className="text-xl  !leading-normal text-primary-700 sm:max-w-5xl">
          <HTMLParser>{html}</HTMLParser>
        </div>
        <ScreenResizer displayOnly="desktop">
          <div></div>
        </ScreenResizer>
        {/* <ScreenResizer displayOnly="desktop">
          <div className="basis-3/12">
            <Heading level={4}>Subscribe to our newsletter</Heading>
            <Typography
              size="large"
              variant="lightDark"
              className="mt-4 font-medium"
            >
              We’ll keep you in the loop on our best blog.
            </Typography>
            <div className="mt-10">
              <Input
                name="email"
                // label="Email"
                placeholder="xyz@gmail.com"
                // onChange={handleChange}
                transparent
                // value={values.email}
                // isDirty={touched.email}
                // validationError={
                //   touched.email && errors.email ? errors.email : undefined
                // }
                startIcon={<MailIcon className="text-primary-500" />}
              />
            </div>
            <Button label="Subscribe" variant="black" className="mt-6" />
          </div>
        </ScreenResizer> */}
      </div>
    </Container>
  );
};
