import React from 'react';

import { ScreenResizer } from '@/components';

import { ContactUsMobile } from './mobile';
import type { ContactUsProps } from './types';
import { ContactUsWeb } from './web';

export const ContactUsSection = ({
  email,
  phone,
  address,
  socials,
  onSubmit,
  RenderAlert,
}: ContactUsProps) => {
  return (
    <>
      <ScreenResizer displayOnly="desktop">
        <ContactUsWeb
          {...{ email, phone, address, socials, onSubmit, RenderAlert }}
        />
      </ScreenResizer>
      <ScreenResizer displayOnly="mobile">
        <ContactUsMobile
          {...{ email, phone, address, socials, onSubmit, RenderAlert }}
        />
      </ScreenResizer>
    </>
  );
};
