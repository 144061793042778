import React from 'react';

import { ScreenResizer } from '@/components';

import { IndustriesServedMobile } from './mobile';
import type { IndustriesServedProps } from './types';
import { IndustriesServedWeb } from './web';

export const IndustriesServed = ({ industries }: IndustriesServedProps) => {
  return (
    <>
      <ScreenResizer displayOnly="desktop">
        <IndustriesServedWeb {...{ industries }} />
      </ScreenResizer>
      <ScreenResizer displayOnly="mobile">
        <IndustriesServedMobile {...{ industries }} />
      </ScreenResizer>
    </>
  );
};
