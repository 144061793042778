import clsx from 'clsx';
import React from 'react';

import { Typography } from '@/components';
import { Container } from '@/layouts/Container';

import type { IndustriesServedProps, IndustryCardProps } from '../types';

const IndustryCard = ({ name, icon, className }: IndustryCardProps) => (
  <div
    className={clsx(
      className,
      'flex flex-col justify-center gap-3 bg-footer px-5 py-6 hover:drop-shadow-md'
    )}
  >
    <div className="flex w-full justify-center">{icon}</div>
    <Typography
      size="large"
      variant="lightDark"
      className="text-center font-titleFont font-medium"
    >
      {name}
    </Typography>
  </div>
);

export const IndustriesServedWeb = ({ industries }: IndustriesServedProps) => {
  return (
    <Container hiddenOverflow>
      <div
        className="grid grid-cols-170-sm justify-center gap-4 xl:grid-cols-196-xl"
        // style={{ gridTemplateColumns: 'repeat(6, 196px)' }}
      >
        {industries.map((industry, index, arr) => {
          if (index === 0)
            return (
              <div className="flex flex-col justify-center">
                <IndustryCard
                  className="h-[160px]"
                  name={industry.name}
                  icon={industry.icon}
                />
              </div>
            );
          if (index === 1)
            return (
              <div className="flex flex-col justify-center gap-3">
                <IndustryCard
                  name={industry.name}
                  icon={industry.icon}
                  className="h-[132px]"
                />
                <IndustryCard
                  className="h-[132px]"
                  name={arr[index + 1]?.name ?? ''}
                  icon={arr[index + 1]?.icon ?? <></>}
                />
              </div>
            );
          if (index === 3)
            return (
              <div className="col-span-2 grid grid-cols-2 gap-4">
                <div className="col-span-2">
                  <IndustryCard
                    className="h-[160px]"
                    name={industry.name}
                    icon={industry.icon}
                  />
                </div>
                <IndustryCard
                  className="h-[132px]"
                  name={arr[index + 1]?.name ?? ''}
                  icon={arr[index + 1]?.icon ?? <></>}
                />
                <IndustryCard
                  className="h-[132px]"
                  name={arr[index + 2]?.name ?? ''}
                  icon={arr[index + 2]?.icon ?? <></>}
                />

                <div className="col-span-2">
                  <IndustryCard
                    className="h-[160px]"
                    name={arr[index + 3]?.name ?? ''}
                    icon={arr[index + 3]?.icon ?? <></>}
                  />
                </div>
              </div>
            );
          if (index === 7)
            return (
              <div className="flex flex-col justify-center gap-3">
                <IndustryCard
                  className="h-[132px]"
                  name={industry.name}
                  icon={industry.icon}
                />
                <IndustryCard
                  className="h-[132px]"
                  name={arr[index + 1]?.name ?? ''}
                  icon={arr[index + 1]?.icon ?? <></>}
                />
              </div>
            );
          if (index === 9)
            return (
              <div className="flex flex-col justify-center">
                <IndustryCard
                  className="h-[160px]"
                  name={industry.name}
                  icon={industry.icon}
                />
              </div>
            );
          return <></>;
        })}
      </div>
    </Container>
  );
};
