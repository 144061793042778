import React from 'react';

import { Heading, Typography } from '@/components';
import { NewsletterForm } from '@/components/NewsletterForm';
import { Container } from '@/layouts/Container';

export const NewsletterWeb = () => {
  return (
    <Container>
      <div className="flex justify-center bg-footer">
        <div className="py-default">
          <Heading level={0} className="text-center text-[40px]">
            Subscribe to our newsletter
          </Heading>
          <Typography
            size="large"
            variant="lightDark"
            className="mt-4 text-center font-medium"
          >
            We’ll keep you in the loop on our best blog.
          </Typography>
          <div className="mt-5">
            <NewsletterForm inputWidth={'100%'} />
          </div>
        </div>
      </div>
    </Container>
  );
};
