import { useRouter } from 'next/router';
import React from 'react';

import { BadgeIcon, Button, Heading, Typography } from '@/components';
import { REDIRECT_TO_NEWS } from '@/constants/variables';

import type { NewsCardProps } from './types';

export const NewsCard = ({
  title,
  description,
  date,
  duration,
  url,
  img,
  isFeatured = false,
}: NewsCardProps) => {
  const router = useRouter();

  return (
    <div className="flex flex-col gap-4 sm:flex-row sm:gap-6">
      <img
        src={img}
        alt={title}
        className="h-[252px] w-full object-cover object-center sm:aspect-[4/3] sm:h-[300px]"
      />
      <div className="flex flex-col justify-center">
        <div className="flex w-full flex-col gap-3 sm:w-[760px] sm:gap-2">
          <div className="mt-4 flex items-center gap-3 text-sm font-medium text-primary-600">
            <Typography size="small" variant="noStyle">
              {date}
            </Typography>
            {duration && (
              <div>
                <ul className="list-inside list-disc">
                  <li>{duration} mins read</li>
                </ul>
              </div>
            )}
            {isFeatured && (
              <div className="ml-2 flex items-center gap-2">
                <BadgeIcon />
                <Typography size="small" className="font-semibold">
                  Featured
                </Typography>
              </div>
            )}
          </div>
          <Heading level={4} weight="semibold">
            {title}
          </Heading>
          <Typography size="large" variant="lightDark">
            {description}
          </Typography>
          <Button
            className="mt-4"
            variant="black"
            label="Read More"
            onClick={() => router.push(REDIRECT_TO_NEWS(url))}
          />
        </div>
      </div>
    </div>
  );
};
