import React from 'react';

import { Heading, Typography } from '@/components';
import { NewsletterForm } from '@/components/NewsletterForm';
import { Container } from '@/layouts/Container';

export const NewsletterMobile = () => {
  return (
    <div className="flex justify-center bg-footer">
      <Container>
        <div className="py-6">
          <Heading level={2} className="text-center">
            Subscribe to our newsletter
          </Heading>
          <Typography
            size="large"
            variant="lightDark"
            className="mt-4 text-center font-medium"
          >
            We’ll keep you in the loop on our best blog.
          </Typography>

          <NewsletterForm inputWidth="100%" />
        </div>
      </Container>
    </div>
  );
};
