import React from 'react';

import { Button, Typography } from '@/components';
import { Container } from '@/layouts/Container';
import type { SolutionType } from '@/types/solution';
import type { SuccessStoriesType } from '@/types/successStories';
import { ConvertStringToDateTime } from '@/utils/DateFormat';
import getImage from '@/utils/getImage';

export const StoryHeader = ({
  story,
}: {
  story: SolutionType | SuccessStoriesType;
}) => {
  return (
    <div
      className="pt-16"
      style={{
        color: story.attributes?.textColor
          ? story.attributes?.textColor
          : 'white',
        background: story.attributes?.bgColor
          ? story.attributes?.bgColor
          : 'black',
      }}
    >
      <Container>
        <div className="flex flex-col gap-8 overflow-hidden pb-10 sm:items-center sm:pb-default md:flex-row">
          <div className="flex basis-1/2 flex-col gap-3 md:gap-4">
            <h1
              className={'font-titleFont text-3xl font-bold md:text-6xl-72lh'}
            >
              {story.attributes?.title}
            </h1>
            <div className="text-sm font-semibold">
              <Typography size="small" variant="noStyle">
                {ConvertStringToDateTime(
                  story.attributes?.publishDate,
                  'only-date'
                )}
              </Typography>
            </div>
            <Typography size="xl" variant="noStyle">
              {story.attributes?.description}
            </Typography>
            <div className="mt-3 flex flex-wrap gap-3">
              {story.attributes?.cta.length > 0 &&
                story.attributes?.cta
                  .sort((a, b) => a.order - b.order)
                  .map((action) => (
                    <Button
                      startIcon={
                        action.icon?.data ? (
                          <img
                            src={getImage(action.icon.data.attributes?.url)}
                            alt="icon"
                            className="h-6 w-6"
                          />
                        ) : undefined
                      }
                      key={action.id}
                      label={action.title}
                      variant={action.variant}
                      onClick={() => window.open(action.link, '_blank')}
                    />
                  ))}
            </div>
          </div>
          <div className="basis-1/2">
            {story.attributes?.story_image && (
              <img
                src={getImage(
                  story.attributes?.story_image?.data?.attributes?.url ||
                    '/images/dhrubok-placeholder.svg'
                )}
                alt="featured"
                className="aspect-[67/60] w-full object-contain object-center sm:aspect-video"
              />
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};
