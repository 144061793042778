import React from 'react';

import { Heading, Typography } from '@/components';
import { Container } from '@/layouts/Container';
import getImage from '@/utils/getImage';

import { ClientForm } from '../../Home/ContactForm/ClientForm';
import type { ContactUsProps } from '../types';

export const ContactUsWeb = ({
  email,
  phone,
  address,
  socials,
  RenderAlert,
  onSubmit,
}: ContactUsProps) => {
  return (
    <div className="relative">
      <Container>
        <div className="flex justify-between gap-10">
          <div className="basis-1/2 bg-primary-900 py-14 pl-[114px] pr-[169px] text-white">
            <Heading level={2} color="white" weight="semibold">
              Contact Us
            </Heading>
            <Typography
              variant="noStyle"
              size="xl"
              className="mt-default font-medium underline"
            >
              {email} <br /> {phone}
            </Typography>
            <Typography
              variant="noStyle"
              size="xl"
              className="mt-8 w-[432px] font-medium"
            >
              {address}
            </Typography>
            <div className="mt-8 flex gap-5">
              {socials &&
                socials.length > 0 &&
                socials.map((social) => (
                  <a href={social.href} key={social.id}>
                    <img
                      src={getImage(social.icon?.data?.attributes.url)}
                      alt={social.icon.data.attributes.alternativeText}
                      className="h-8 w-8"
                    />
                  </a>
                ))}
            </div>
          </div>
          <div className="basis-1/2">
            {RenderAlert()}
            <ClientForm onSubmit={onSubmit} />
          </div>
        </div>
      </Container>
    </div>
  );
};
