import React from 'react';

import { Heading, Tag, Typography } from '@/components';
import { Container } from '@/layouts/Container';
import type { BlogData } from '@/types/blogResponse';
import { ConvertStringToDateTime } from '@/utils/DateFormat';
import getImage from '@/utils/getImage';

export const PostHeader = ({ post }: { post: BlogData }) => {
  return (
    <Container>
      <div className="flex flex-col gap-8 pb-10 sm:flex-row sm:items-center sm:pb-default">
        <div className="flex basis-1/2 flex-col gap-3 sm:gap-4">
          <div className="flex flex-wrap gap-2">
            {post.attributes.categories &&
              post.attributes.categories.data.map((category, index) => (
                <Tag
                  key={category.id}
                  index={index}
                  label={category.attributes.name ?? ''}
                />
              ))}
          </div>
          <Heading level={2} renderH1 className="!leading-tight">
            {post.attributes.title}
          </Heading>
          <div className="flex items-center gap-3 text-sm font-semibold text-primary-600">
            <Typography size="small" variant="noStyle">
              {ConvertStringToDateTime(
                post.attributes.publishDate,
                'only-date'
              )}
            </Typography>
            <div>
              <ul className="list-inside list-disc">
                <li>{post.attributes.reading_time} mins read</li>
              </ul>
            </div>
          </div>
          <Typography size="large" variant="lightDark" className="font-medium">
            {post.attributes.description}
          </Typography>
          <div className="mt-6 flex items-center gap-4">
            {post.attributes.author?.data.attributes.profilePhoto && (
              <div className="h-10 w-10 rounded-full">
                <img
                  src={getImage(
                    post.attributes.author?.data?.attributes.profilePhoto?.data
                      ?.attributes.url
                  )}
                  alt={post.attributes.author.data.attributes.displayName}
                  className="rounded-full"
                />
              </div>
            )}

            <div className="flex flex-col">
              <Typography className="font-bold">
                {post.attributes.author?.data.attributes.displayName}
              </Typography>
              <Typography size="small">
                {post.attributes.author?.data.attributes.designation}
              </Typography>
            </div>
          </div>
          {/* <SocialShare /> */}
        </div>
        <div className="basis-1/2">
          {post.attributes.featured_image && (
            <img
              src={getImage(
                post.attributes.featured_image?.data?.attributes.url
              )}
              alt="featured"
              // className="aspect-[67/60] w-full object-cover object-center sm:aspect-[593/530]"
              className="aspect-[67/60] w-full object-cover object-center sm:aspect-video"
            />
          )}
        </div>
      </div>
    </Container>
  );
};
